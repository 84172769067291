

.intro-desc {
    position: absolute;
    margin-top: 3rem;
    padding-bottom: 10px;
    
    bottom: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    z-index: 1;




  @media screen and (max-width: 768px) {
    line-height: 1.75rem !important;
    font-size: 1.5rem;
  }
  
    line-height: 3rem !important;
    font-size: 3rem;
    text-transform: uppercase;

    
    h3 {
        padding: .75rem;
    }
    
}

.intro-effect {
    position: relative;
    margin: 10px;
    background-color: #060C1F;
}

.intro-effect::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    -webkit-box-shadow: 0 0 17px 3px #ffff01,0 0 4px 2px #ffff01;
            box-shadow: 0 0 17px 3px #ffff01,0 0 4px 2px #ffff01;
    z-index: -1;
    -webkit-animation-name: half-yellow-shadow;
            animation-name: half-yellow-shadow;
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
    -webkit-animation-duration: 5s;
            animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .intro-effect::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    -webkit-box-shadow: 0 0 17px 3px #0ff,0 0 4px 2px #0ff;
            box-shadow: 0 0 17px 3px #0ff,0 0 4px 2px #0ff;
    z-index: -1;
    -webkit-animation-name: half-cyan-shadow;
            animation-name: half-cyan-shadow;
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
    -webkit-animation-duration: 5s;
            animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  @keyframes half-yellow-shadow {
    0% {
      top: 0;
      left: 0;
      height: 50%;
      width: 50%;
    }
    16.66% {
      top: 0;
      left: 0;
      height: 50%;
      width: 100%;
    }
    32.32% {
      top: 0;
      left: 50%;
      height: 50%;
      width: 50%;
    }
    49.98% {
      top: 50%;
      left: 50%;
      height: 50%;
      width: 50%;
    }
    66.64% {
      top: 50%;
      left: 0;
      height: 50%;
      width: 100%;
    }
    83.3% {
      top: 50%;
      left: 0;
      height: 50%;
      width: 50%;
    }
    100% {
      top: 0;
      left: 0;
      height: 50%;
      width: 50%;
    }
  }

  @-webkit-keyframes half-cyan-shadow {
    0% {
      bottom: 0;
      right: 0;
      height: 50%;
      width: 50%;
    }
    16.66% {
      bottom: 0;
      right: 0;
      height: 50%;
      width: 100%;
    }
    32.32% {
      bottom: 0;
      right: 50%;
      height: 50%;
      width: 50%;
    }
    49.98% {
      bottom: 50%;
      right: 50%;
      height: 50%;
      width: 50%;
    }
    66.64% {
      bottom: 50%;
      right: 0;
      height: 50%;
      width: 100%;
    }
    83.3% {
      bottom: 50%;
      right: 0;
      height: 50%;
      width: 50%;
    }
    100% {
      bottom: 0;
      right: 0;
      height: 50%;
      width: 50%;
    }
  }
  
  @keyframes half-cyan-shadow {
    0% {
      bottom: 0;
      right: 0;
      height: 50%;
      width: 50%;
    }
    16.66% {
      bottom: 0;
      right: 0;
      height: 50%;
      width: 100%;
    }
    32.32% {
      bottom: 0;
      right: 50%;
      height: 50%;
      width: 50%;
    }
    49.98% {
      bottom: 50%;
      right: 50%;
      height: 50%;
      width: 50%;
    }
    66.64% {
      bottom: 50%;
      right: 0;
      height: 50%;
      width: 100%;
    }
    83.3% {
      bottom: 50%;
      right: 0;
      height: 50%;
      width: 50%;
    }
    100% {
      bottom: 0;
      right: 0;
      height: 50%;
      width: 50%;
    }
  }

.intro-dub {
  position:absolute;
  height: 11rem;
  top: 12%;
  left: 25%;
  margin: 1rem;
  
  animation-name: flyin-left;
  animation-duration: 1s;
  // animation-delay: 0.5s;
  animation-timing-function: ease-out;

  @keyframes flyin-left {
    from {
      transform: translateX(-300%);
    }
    to {
      transform: translateX(0);
    }
  }

    @media screen and (max-width: 768px) {
      height: 5rem;
      left: 10%;
      top: 16%;
    }
}

.intro-platez {
  position: absolute;
  height: 11rem;
  bottom: 40%;
  right: 15%;
  margin: 1rem;

  animation-name: flyin-right;
  animation-duration: 1s;
  // animation-delay: 0.5s;
  animation-timing-function: ease-out;

  @keyframes flyin-right {
    from {
      transform: translateX(200%);
    }
    to {
      transform: translateX(0);
    }
  }
    @media screen and (max-width: 768px) {
      height: 5rem;
      bottom: 56%;
      right: 3%;

    }
}