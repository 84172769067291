.navbar {
    height: 80px;

    .wrapper{
        padding: 10px 30px;
        display: flex;
        justify-content: space-between;
        alight-items: center;

        .item{
            display: flex;
            align-items: center;
            font-size: 18px;
        }

        .left{
            display: flex;
            align-items: center;
            gap: 25px;
        }
        .center{
            font-size: 30px;
            letter-spacing: 2px;
        }
        .right{
            display: flex;
            align-items: center;
            gap: 25px;

            .icons{
                display: flex;
                gap: 15px;
                color: #777;
                cursor: pointer;
            }
        }

    }
}