// .featuredCard {
//     width: 20vw !important;
// }

.featuredStrains{
    //margin: 100px 200px;
    margin-top: 100vh; //to make room for the video sting above it
    background-color: black;
position: relative;



    .top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 10;

        h1{
            flex: 2;
            text-transform: capitalize;
            z-index: 10;
            margin-left: 15%;
            margin-top: 10px;
            color: white;
            text-transform: uppercase;
            font-size: 4rem;
            text-shadow:
            0 0 5px #fff,
            0 0 8px #f6ff00,
            0 0 5px #f6ff00,
            0 0 10px #f6ff00,
            0 0 20px #f6ff00,
            0 0 30px #f6ff00,
            0 0 40px #f6ff00,
            0 0 60px #f6ff00;
          animation: neon 1.5s ease-in-out infinite alternate;
        }

        @keyframes neon {
            from {
              text-shadow:
                0 0 5px #f6ff00,
                0 0 10px #f6ff00,
                0 0 15px #f6ff00,
                0 0 20px #f6ff00,
                0 0 30px #f6ff00,
                0 0 40px #f6ff00,
                0 0 50px #f6ff00,
                0 0 70px #f6ff00;
            }
            to {
              text-shadow:
                0 0 10px #f6ff00,
                0 0 15px #f6ff00,
                0 0 20px #f6ff00,
                0 0 30px #f6ff00,
                0 0 40px #f6ff00,
                0 0 50px #f6ff00,
                0 0 60px #f6ff00,
                0 0 80px #f6ff00;
            }
          }

        p{
            flex:2;
            color: whitesmoke;
            text-transform: uppercase;
            font-family: Jost;
            z-index: 10;
            padding: 5vw;
        }

        @media only screen and (max-width: 767px) {
            p {
                font-size: 1rem;
            }

            h1 {
                font-size: 2.5rem;
            }
        }

        @media only screen and (min-width: 768px) {
            p {
                font-size: 1.75rem;
            }
        }
    }

    .bottom{
        // display: flex;
        // justify-content: center;
        // gap: 50px;
        // object-fit: cover;
        // z-index: 10;

        h2, .IHS, .thc {
          z-index: 10;  
        }


        // @media(max-width: 768px) {
        //     flex-direction: column;
        //     gap: 0;}




        
    }

    
    svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    opacity: 0.7;
    
    }

}