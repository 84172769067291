
.moving-border{

    // ****** V1 below, trippy moving *****
    // border: 8px solid;
    // clip-path: polygon(20px 0, calc(100% - 20px) 0, 100% 20px, 100% calc(100% - 20px), calc(100% - 20px) 100%, 20px 100%, 0 calc(100% - 20px), 0 20px);
    // border-image: linear-gradient(var(--angle), #12c2e9, #c471ed, #f64f59) 1;
    // animation: 5s rotate linear infinite;


    //****** fire colored V2, non-moving *******
    // align-items: center;
    // $border: 8px;
    // color: #FFF;
    // background: #000;
    // background-clip: padding-box; /* !importanté */
    // border: solid $border transparent; /* !importanté */
    // border-radius: 1em;
    //
    // &:before {
    //     content: '';
    //     position: absolute;
    //     top: 0; right: 0; bottom: 0; left: 0;
    //     z-index: -1;
    //     margin: -$border; /* !importanté */
    //     border-radius: inherit; /* !importanté */
    //     background: linear-gradient(to right, red, orange);
    //   }
    
    --borderWidth: 12px;
    background: #1D1F20;
    position: relative;
    border-radius: var(--borderWidth);

}

.moving-border:after {
        content: '';
        position: absolute;
        top: calc(-1 * var(--borderWidth));
        left: calc(-1 * var(--borderWidth));
        height: calc(100% + var(--borderWidth) * 2);
        width: calc(100% + var(--borderWidth) * 2);
        background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
        border-radius: calc(2 * var(--borderWidth));
        z-index: -1;
        animation: animatedgradient 12s ease alternate infinite;
        background-size: 300% 300%;
    }

    @keyframes animatedgradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }

    @keyframes rotate{
        to {
            --angle: 360deg;
        }
    }

    @property --angle {
        syntax: '<angle>';
        initial-value: 0deg;
        inherits: false;
    }


.card{
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    flex-direction: column;

    z-index: 102;
    
    // gap: 10px;
    // padding: 50px;
    padding-top: 1rem;
    padding-bottom: 2rem;
    padding-right: 1rem;
    padding-left: 1rem;
    position:relative;

    .image{
        position: relative; // to float headings on image

        // background: linear-gradient(to top, #ff0033, #cc0077, #8f00b3, #5900b3, 0.75);
        background-color: rgba(255, 0, 51, 0.15);
        box-shadow: inset 0 0 15px rgb(135, 2, 93);

        animation: animateBackground 10s linear infinite;

        width: 350px;
        height: 350px;
        // overflow: hidden;
        position: relative;

        @keyframes animateBackground {
            0% {
                background-position: 0 0;
            }
            50% {
                background-position: 0 200%;
            }
            100% {
                background-position: 0 0;
            }
        }

        

        transition: transform 0.3s ease-out;
        // zoom and rotate on hover v2
        &:hover {
            transform: scale(1.1) rotate(3deg);
            // z-index: 5000 !important; doesnt seem to work? not that big an issue, but its for overlapping on other tiles when hovered on specific viewport sizes (med-tablet)
        }

        // switches to second img on hover, already in html - prior version

        // &:hover{
        //     .secondImg{
        //         z-index: 3;
        //         width: 100%;
        //         display: inline;
        //         // transform: scale(1.4);
        //         overflow: visible;


        //     }

        //     .mainImg{
        //         display: none;
        //     }
        // }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
        }

        .mainImg{
            z-index: 1;
            max-height: 90vh;
            //max-height: 90vw;;
        }

        .secondImg{
            display: none;
            object-fit: contain;
          //  transition: transform .2s;
        }

        .dropped{
            position: absolute;
            top: 5px;
            left: 5px;
            background-color: white;
            color: teal;
            padding: 3px 5px;
            z-index: 3;
            font-weight: 500;
            font-size: 12px;
        }

    }

    h2{
        text-transform: uppercase;
        font-size: 3.5rem;
        line-height: 3rem !important;
        font-weight: 700;
        color: white;

        // text decoration
        text-shadow: 1px 1px 6px #000;




        // position: relative;
        position: absolute;
        top: 60%;
        left: 50%;
        // bottom: 25%;
        transform: translate(-50%, -50%); // center h2
        z-index: 1; // make sure h2 appears above img
    }



    // .cross{
    //     // to position over image
    //     position: absolute;
    //     bottom: 20px; // h3 vertical position
    //     left: 50%; // h3 horizontal position
    //     text-transform: uppercase;
    //     // transform: translateX(-50%);
    //     transform: translate(-50%, -50%);
    //     z-index: 1;

    //     display: flex;
    //     gap: 20px;
        
    //     h3{
    //         font-family: 'Ropa Sans', sans-serif;
    //         font-size: 1.75rem;
    //         font-weight: 400;
    //         color: green;
    //     }
    // }

    .cross {
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        // background-color: white;
        // color: green;
        background-color: purple;
        color: white;
        // padding: 3px 5px;
        padding: .3rem 1rem;
        z-index: 100;
        

        font-family: 'Ropa Sans', sans-serif;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 1.5rem !important;
        line-height: 1.5rem !important;

        // text-shadow:  2px 2px 4px #000 !important;
        // text-shadow: 0px 1px 4px rgba(255, 255, 255, 0.5);

    }


    .breeder{
        // below to position over image
        position: absolute;
        bottom: 0; // vertical position
        left: 50%; // horizontal position
        transform: translateX(-50%); // centers horizontally
        z-index: 1;

        display: flex;
        gap: 20px;
        
        h3{
            font-family: 'Ropsa Sans', sans-serif;
            font-style: italic;
            font-size: 1rem;
            font-weight: 600;
            color: green;
        }

    }

    // .IHS{
    //     display: flex;
    //     gap: 20px;
        
    //     h3{
    //         font-family: 'Jost', sans-serif;
    //         text-transform: uppercase;
    //         font-size: 1.5rem;
    //         font-weight: 400;
    //         color: red;
    //     }

    // }

    .IHS-span{
            position: absolute;
            top: 0px;
            right: 0px;
            // background-color: white;
            // color: green;
            background-color: purple;
            color: white;
            padding: 3px 5px;
            z-index: 100;
            

            font-family: 'Ropa Sans', sans-serif;
            font-weight: 400;
            text-transform: uppercase;
            font-size: 1.5rem !important;
            line-height: 1.5rem !important;
        
    }

    .strainType{
        display: flex;
        gap: 20px;
    }


}

// modal content below
// main modal window CSS, not content itself, kinda confusing should probably combine everything

$white: #fff;
$blue: #1e90ff;
$green: #39d24a;
$yellow: #ffe244;
$pink: #ff36a3;

  .modal-box {

    position: relative;
    z-index: 9999;
    width: 70%;
    max-height: 90vh;
    height: 80%;
    background-color: gray;
    padding: 1rem;
    border-radius: 35px;

  }

  .slide-in {
    transition: transform 0.3s ease-out;
    transform: translate3d(0, -100%, 0);
  }


  @media(max-width: 768px) {
    .modal-box {
        width: 80% !important;
        min-height: 80% !important;
        overflow-y: auto;
    }

    .modal-box::-webkit-scrollbar {
        // display: none;
    }
}