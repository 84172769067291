body {
    background: black;
}

.mi-wrapper {
    background: black;
    width: 100%;
    display: flex;
    justify-content: center;
}




.mi-inner-wrapper {
    // margin-top: 1.25rem;
    // font-size: 2rem;
    line-height: 2.75rem;
    color: white;
    // padding-left: 2rem;
    // padding-right: 2rem;
    // padding-bottom: 3rem;
    @media only screen and (max-width: 767px) {
     h2 {
        padding-bottom: 1.25rem;
        padding-top: 2rem;
        text-transform: uppercase;
        font-size: 3.25rem;
        line-height: 2.75rem;
        text-shadow: 2px 2px 4px rgba(7, 157, 35, 0.8);
    }

    p {
        padding-right: 2rem;
        padding-left: 2rem;
        padding-bottom: 2.5rem;
        padding-top: 1.5rem;
        font-size: 1.5rem;
        line-height: 1.75rem;
    }

    ul {
        padding-right: 1.5rem;
    }

    li {
        // line-height: 150%;
        // padding-right: 1.5rem;
        padding-bottom: .80rem;
        font-size: 1.5em;
        line-height: 1.35rem;
        text-shadow: 2px 2px 4px rgba(7, 157, 35, 0.8);
    }

    }

    @media (min-width: 768px) {
        h2 {
            padding-top: 5rem;
            padding-bottom: 2rem;
            text-transform: uppercase;
            font-size: 5.5rem;
            line-height: 4.5rem;
            text-shadow: 2px 2px 4px rgba(7, 157, 35, 0.8);
        }
    
        p {
            padding-right: 10rem;
            padding-left: 10rem;
            padding-bottom: 2.5rem;
            padding-top: 1.5rem;
            font-size: 2.75rem;
            line-height: 3rem;
        }
    
        ul {
            padding-right: 1.5rem;
            padding-top: 2.5rem;
            padding-left: 5rem;
        }
    
        li {
            padding-bottom: .6rem;
            font-size: 2.75rem;
            line-height: 3rem;
            text-shadow: 3px 3px 6px rgba(7, 157, 35, 0.8);
        }
      }

    // li {
    //     padding-left: 15px;
    // }

// img {
//    max-width: 70%;
//    max-height: 70%;
// //   width: 100%;
// //   height: 100%;
//   object-fit: cover;
//   object-position: center center;
// //   opacity: 0.65;

//   @media (max-width: 900px) {
//     min-height: 350px;
//     overflow: visible !important;
//   }

// }


    .aboutPic {
        max-width: 70%;
        max-height: 70%;
        margin-left: -10px;
        margin-bottom: -10px;
       object-fit: cover;
       object-position: center center;
       
     
        @media (min-width: 0px) and (max-width: 500px) {
        margin-bottom: -180px;
        margin-left: 40px;
        min-height: 300px;
        overflow: visible;
        }

       @media (min-width: 501px) and (max-width: 900px) {
         min-height: 450px;
         overflow-x: visible !important;
         margin-left: 45px;
         margin-bottom: -50px;
       }

       @media (min-width: 901px) and (max-width: 1450px) {
        min-height: 700px;
        overflow-x: visible !important;
        margin-left: 40px;
        margin-bottom: -50px;
      }

       animation: float 8s ease-in-out infinite;
    }

    @keyframes float {
        0% {
          transform: translate(0px, 0px) translateX(0px);
        }
        25% {
          transform: translate(0px, 5px) translateX(5px);
        }
        50% {
          transform: translate(0px, 0px) translateX(-2.5px) scale(1.05);
        }
        75% {
          transform: translate(0px, 5px) translateX(-5px) scale(.98);
        }
        100% {
          transform: translate(0px, 0px) translateX(0px) scale(1);
        }
      }
}

.iykyk {
    z-index: 100;
    h2, p {
        text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
    }

}