

.contactFormWrapper {
  display: flex;
  height: 100vh;
  justify-content: center; /*center vertically */
  align-items: center; /* center horizontally */
  padding: 10px;
  box-sizing: border-box;
    justify-content: center; /*center vertically */
  align-items: center; /* center horizontally */
  background: linear-gradient(135deg, var(--main-blue), var(--main-purple));
  height: 100vh;
}

fieldset {
  border: 0;
  padding: 5px;
}
.container {
  max-width: 700px;
  width: 100%;

  padding: 25px 30px;
  border-radius: 15px;
}

.contactFormWrapper .container {
  background: rgba(255, 255, 255, 0.27);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(4.9px);
  border: 2px solid rgba(199, 171, 255, 0.39);
}

:root {
  --main-blue: #71b7e6;
  --main-purple: #9b59b6;
  --main-grey: #ccc;
  --sub-grey: #d9d9d9;
}

// body {

// }

.title {
  // margin-top: 60px;
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 2.75rem;
  position: relative;

  ::before {
    content: "";
    position: absolute;
    height: 8px;
    width: 3rem;
    background: linear-gradient(-135deg, var(--main-blue), var(--main-purple));
    left: 0;
    bottom: -15px;
  }
}

.userDetails {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0 12px 0;
}

.inputBox {
  width: calc(100% / 2 - 20px);
  margin-bottom: 15px;

    .details {
      font-size: 1rem;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 5px;
      display: block;
    }

    input {
      height: 45px;
      width: 100%;
      outline: none;
      border-radius: 5px;
      border: 1px solid var(--main-grey);
      padding-left: 15px;
      font-size: 16px;
      border-bottom-width: 2px;
      transition: all 0.3s ease;

      &:focus, &:valid {
        border-color: var(--main-purple);
      }
    }

    textarea {
      height: 125px;
      resize: vertical;
    }

    .messageInput {
      width: 100%;
      outline: none;
      border-radius: 5px;
      border: 1px solid var(--main-grey);
      padding-left: 15px;
      font-size: 16px;
      border-bottom-width: 2px;
      transition: all 0.3s ease;

      &:focus, :valid {
        border-color: var(--main-purple);
      }
    }
}



.button {
    height: 50px;
    margin: 25px 0;
  width: 100%;
  outline: none;
  color: #fff;
  border: none;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 5px;
  background: linear-gradient(135deg, var(--main-blue), var(--main-purple));
  transition: all 0.3s ease;

  &:hover {
    background: linear-gradient(-135deg, var(--main-blue), green);
  }
}

.width100 {
  width: 100% !important;
}
// $primary-color: green;
// $secondary-color: #3F3D55;
// $secondary-120:#3E3C53;
// $background-color: #FFF9FB;

// .App {
//   background: black;
// }

// .contactForm {
//     margin-top: 80px;
//     z-index: 3;
//     width: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     .contactFormWrapper {
//       border-radius: 12px;
//       margin: auto;
//       background-color:  $secondary-color;
//       box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
//       width: 90%;

//       // @include for-large {
//       //   width: 50%;
//       // }

//       // @include for-xxlarge {
//       //   width: 35%;
//       // }
//       .contactLabel {
//         color:  $primary-color;
//         font-size: 2rem;
//         font-family: monospace;
//         font-weight: 500;
//         letter-spacing: 1.4px;
//         text-transform: uppercase;
//         margin: 2rem 0rem 2rem 2rem;
//       }
//       .formheader {
//         width: 100%;
//         background:  $primary-color;
//         border-top-left-radius: 12px;
//         border-top-right-radius: 12px;
//         padding: 1.5rem 2rem;
//         margin: 0;
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//         box-sizing: border-box;
//         .cross {
//           cursor: pointer;
//           svg {
//             font-size: 3rem;
//             color:  $background-color;
//           }
//         }

//         .sm {
//           display: flex;
//           align-items: center;
//           justify-content: center;
//           gap: 3rem;

//           a {
//             margin: 0;
//             padding: 0;
//             font-size: 2.2rem;
//             color:  $secondary-120;

//             svg {
//               vertical-align: middle;
//             }
//           }
//         }
//       }

//       .formcontact {
//         padding: 2rem;
        
//         .afterForm {
//           font-size: 1.8rem;
//           font-weight: 900;
//           color:  $background-color;
//           letter-spacing: 0.2rem;
//         }
//         form {
//           display:grid;
//         grid-template-columns: repeat(2,1fr);
//           fieldset {
//             border: none;
//             margin-bottom: 2rem;
//             label {
//               display: block;
//               text-align: left;
//               color:  $primary-color;
//               font-weight: 500;
//               letter-spacing: 0.2rem;
//               font-family: "Montserrat", sans-serif;
//               font-size: 1.4rem;
//               text-transform: uppercase;
//             }

//             input,
//             textarea {
//               width: 100%;
//               margin-top: 1rem;
//               border: none;
//               outline: 0;
//               color:  $background-color;
//               letter-spacing: 0.2rem;
//               font-size: 1.6rem;
//               background-color: transparent;
//               border-bottom: 1px solid  $primary-color;

//               &:focus{
//                 color:  $background-color;
//                 background-color: transparent;

//               }
//               &:active{
//                 color:  $background-color;
//                 background-color: transparent;

//               }
//             }
//           }

//           button {
//             background-color:  $primary-color;
//             border: none;
//             color:  $secondary-color;
//             font-weight: bolder;
//             font-size: 1.8rem;
//             font-family: "Sirin Stencil";
//             text-transform: uppercase;
//             letter-spacing: 1.8px;
//             width: 80%;
//             border-radius: 12px;
//             margin: auto;
//             padding: 0.6rem;
//             border: 2px solid transparent;
//             cursor: pointer;

//             &:hover {
//               border-color:  $primary-color;
//               background-color: transparent;
//               color:  $primary-color;
//             }
//           }
//         }
//       }
//     }
//   }