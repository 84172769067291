span {
    color: white;
    line-height: 4rem;

}

.catImg {
    display: block;
    width: 100%;
    height: auto;
}

.headerDistortion span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 5rem;
    color: #fff;
    text-shadow:
    0 0 10px #ff00ff, /* magenta */
    0 0 20px #00ffff, /* cyan */
    0 0 30px #ffff00, /* yellow */
    0 0 40px #ff0000, /* red */
    0 0 50px #00ff00, /* green */
    
 
  }

//   .list-items {
//     z-index: 102;
//   }
  .wave {
    background: rgb(255 255 255 / 25%);
    border-radius: 1000% 1000% 0 0;
    position: absolute;
    width: 200%;
    height: 60vh;
    animation: wave 10s -3s linear infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.4;
    bottom: 0;
    left: 0;
    z-index: 99;
    overflow-x: hidden !important;
}

.wave:nth-of-type(2) {
    bottom: -1.25em;
    animation: wave 18s linear reverse infinite;
    opacity: 0.8;
    overflow-x: hidden !important;
}

.wave:nth-of-type(3) {
    bottom: -2.5em;
    animation: wave 20s -1s reverse infinite;
    opacity: 0.9;
    overflow-x: hidden !important;
}

@keyframes wave {
    2% {
        transform: translateX(1);
    }

    25% {
        transform: translateX(-25%);
    }

    50% {
        transform: translateX(-50%);
    }

    75% {
        transform: translateX(-25%);
    }

    100% {
        transform: translateX(1);
    }
}


.categoryWrapper{
    position: relative;

    // background: linear-gradient(135deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 33%, rgba(255,255,255,0.05) 33%, rgba(255,255,255,0.0) 66%, rgba(255,255,255,0) 66%, rgba(255,255,255,0) 100%), black;
    overflow: hidden;
    // background: linear-gradient(315deg, rgba(101,0,94,1) 3%, rgba(60,132,206,1) 38%, rgba(48,238,226,1) 68%, rgba(255,25,25,1) 98%);
    background: linear-gradient(315deg, rgb(211, 0, 0) 3%, rgb(255, 110, 48) 38%, rgba(195, 0, 255, 1) 68%, rgba(88, 0, 230, 1) 98%);

    animation: gradient 12s ease infinite;
    
    background-size: 400% 400%;
    background-attachment: fixed;
    
    @keyframes gradient {
        0% {
            background-position: 0% 0%;
        }
        50% {
            background-position: 100% 100%;
        }
        100% {
            background-position: 0% 0%;
        }
    }

    



    min-height: 100vh;
    height: 100%;
    // margin-top: 100vh; // to make room for video loop

    .headerDistortion {
        position: relative;

        //  margin-top: 80px;
        // padding-top: 150px;
        border: .5rem solid purple;
        overflow: hidden;
        height: 20vh;
        object-fit: cover;

    }

    .catImg:nth-child(1) {
        filter: url(#transmissionerror);
    }

    .catImg:nth-child(2) {
        filter: grayscale(100%) constrast(1000%);
        mix-blend-mode: multiply;
    }
    
    svg {
        position: absolute;
    }

    // large screen style
    // @media only screen and (min-width: 768px) {
    //     .catImg {
    //         //width: 100%;
    //         background-image: url('./banner-lg.jpg')
    //         height: 50vh;
    //         width: auto;
    //         object-fit: cover;
    //         overflow: hidden;
    //     }
    // }

    //mobile
    // @media only screen and (max-width: 767px) {
    //     .catImg {
    //         background-image: url('./banner-sm.jpg')
    //         height 50vh;
    //         width: auto;
    //         object-fit: cover;
    //         overflow: hidden;
    //     }
    // }
    
}