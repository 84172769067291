

.modalContainer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0, 0.45);
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
    
.modal_main {
      width: 16vw;
      height: auto;
      // min-height: 275px;
      min-width: 250px;
      text-align: center;
    
      border-radius: 20px;
    
      // background-color: rgba(255, 255, 255, 0.35);
      // backdrop-filter: blur(10px);
      // box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.8);
    
      animation-name: flyin-bottom;
      animation-duration: 0.75s;
      animation-timing-function: ease-out;
      animation-fill-mode: forwards;
      
      @keyframes flyin-bottom {
        from {
          transform: translateY(-200%);
        }
        to {
          transform: translateY(0);
        }
      }
        @media screen and (max-width: 768px) {
          height: 5rem;
          bottom: 56%;
          right: 3%;
    
        }
    
    }

    .bg {
      /* bg animation */
      background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
      background-size: cover;
      animation: gradient1 5s ease infinite;
      overflow: hidden;
      border-radius: 20px;

      backdrop-filter: blur(10px);
      box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.8);
    }
    
    @keyframes gradient1 {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
    

    
    .modal_header_title {
      font-size: 68px;
      position: relative;
      // border-bottom: 2px dashed #dddddd;
      text-align: center;
      color: transparent;
      font-weight: 500;
      padding-bottom: 5px;
      line-height: 90%;

      text-shadow: 0px 0px 3px black;

      padding: 1rem;
    }
    
    .modal_content {
      font-size: 28px;
      position: relative;
      text-align: center;
      color: white;
      padding-bottom: 10px;
      padding-top: 10px;
    
    }
    
    @media(max-width: 1200px){
       .modal_header_title {
        font-size: 48px;
        padding-top: 50px;
      }}
    
    @media(max-width: 768px){
      .modal_content {
        font-size: 18px;
        
      }}
    
    @media(max-width: 768px){
      .modal_main {
        height: auto;
      }}
    
    @media(max-width: 1200px){
      .modal_main {
        height: auto;
    }}
    
    
    
    .modal_warning {
      font-size: 80px;
      position: relative;
      text-align: center;
      color: white;
      font-weight: 800;
      margin-bottom: -10px;
      padding-top: 10%;

  
      text-shadow: none;
      -webkit-text-stroke: 2px white;
      // text-stroke: 2px white;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      // text-fill-color: transparent;



    }