body {
  background: black;
}

.wrapper {
  // margin-top: 80px;
  background: black;
  // height: 100vh !important;
  width: 100%;
  display: flex;
  justify-content: center;
}



.right {
  padding-right: 0 auto !important;
  background: black;
  // padding-right: 50px !important;

  @media only screen and (min-width: 900px) {
    padding-right: 50px !important;
  }

  // @media only screen and (min-width: 768px) and (max-width: 1280px) {
  //   padding-right: 0 auto !important;
  // }
}

.innerWrapper {

// img {
//   //  max-width: 100%;
//   //  max-height: 100%;
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   object-position: center center;
// }

margin-top: 80px;

h2 {
  margin-top: 1rem;
  font-size: 5.5rem;
  line-height: 3.5rem;
  color: white;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 3rem;
  @media only screen and (max-width: 767px) {
    padding-right: 1rem;
    padding: 1rem;
    font-size: 3rem;
    line-height: 1.8rem;
    padding-top: 10px;
    padding-bottom: 25px;
  }
}

// p {
//   padding-bottom: 2rem;
//   padding-left: 6rem;
//   padding-right: 8rem;
// }

ul {
  padding-top: 50px;
}
li {
  padding: .5rem;
}

p, li {
  color: white;
  font-size: 2rem;
  font-weight: 500;
  padding-top: 20px;
  padding-bottom: 2rem;
  padding-left: 6rem;
  padding-right: 8rem;
  line-height: 2.5rem;
  // padding: 4rem;

  @media only screen and (max-width: 767px) {
    font-size: 1.25rem;
    line-height: 1.75rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 4rem;
    padding-right: 4rem;
    // padding-left: 1rem;
  }
}
}
// .container-top {
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     align-items: center;
//     background-color: white;
//   }
  
//   .video-container-top {
//     width: 50%;
//     height: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;

//   }
  
//   .video {
// min-width: 100%;
// min-height: 100%;;
// width: auto;
// height: auto;
// object-fit: cover;
// }
  
//   .text-container-top {
//     width: 50%;
//     padding: 3rem;
//     font-size: 1.5rem;

//     h2 {
//         text-transform: uppercase;

//     }

//     h3 {
//         text-transform: uppercase;
//         padding-top: 1rem;
//     }

//     p {
//         padding-top: .5rem;
//     }
//   }









  // .container-bottom {
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: center;
  //   align-items: center;
  //   background-color: white;
  // }
  
  // .video-container-bottom {
  //   width: 100%;
  //   height: 100%;
  // //   width: 25%;
  // //   height: 25%;
  // //   display: flex;
  // //   justify-content: center;
  // //   align-items: center;

  // }
  
  
  // .text-container-bottom {
  //   width: 50%;
  //   padding: 3rem;
  //   font-size: 1.5rem;

  //   h2 {
  //       text-transform: uppercase;

  //   }

  //   h3 {
  //       text-transform: uppercase;
  //       padding-top: 1rem;
  //   }

  //   p {
  //       padding-top: .5rem;
  //   }
  // }

  // figure {

  // background-color: #000;
  // justify-content: center;
  // align-items: center;
  // width: 100%;
  // height: 0;
  // padding-bottom: 100%;
  // position: relative;


  // }
  figure {
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: #000;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 0;
    padding-bottom: 100%; /* this sets the aspect ratio to 1:1 */
    position: relative;
  
    /* Add a red radial gradient */
    background: radial-gradient(
      ellipse at center,
      rgba(255, 0, 0, 0.8) 0%,
      rgba(255, 0, 0, 0) 70%
    );
  
  }



  .stripes-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  filter: drop-shadow(0 0 20px rgb(217, 7, 7));
  overflow: hidden;
  position: absolute;
  
}

  .stripe {
  width: 100%;
  height: 15px;
  background-color: #ff0000;
  animation: scroll 1s linear infinite;
  
  @keyframes scroll {
    from {
    transform: translateY(-30px);
    }
}
  }  

.stripe + .stripe {
    margin-top: 15px;
}

.gradient-mask {
  width: auto;
  height: 100%;
  background: linear-gradient(to bottom, rgb(34, 60, 255) 0%, rgb(115, 8, 119) 100%);
  mix-blend-mode: multiply;
  position: absolute;
}

.logo {
  transform: scale(1) translateY(0);
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}