
// Barlow, Jost, Ropa Sans

@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;700&family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,800&family=Ropa+Sans:ital@0;1&display=swap');

* {
    font-family: 'Barlow', sans-serif;

}

.home{
//     overflow: hidden; // only for the img carousel slightly extending past edges of homepage for some reason
//     overflow-x: hidden;
 }

body {
    overflow-x: hidden;
}
