

.modalCard {
    // background: radial-gradient(circle at bottom, navy 0, black 100%);
    background: black;
    overflow: auto; // allows scrolling on modal
    min-height: 50%;
    max-height: 80vh;

    
    .selectedImg {
      max-width: 100%;
      max-height: 100vh;
      // min-height: 50vh;
      width: auto;
      // height: 650px;
      object-fit: contain;
}
}


// .MuiModal {
//     background-color: black !important;
// }



.cardHeader // holds title and THC% 
{
    background: black; // match .modalCard color
    padding-top: 2rem;
    flex-direction: column;

    @media only screen and (max-width: 767px) {
      padding-top: 0;
    }
}

.cardTitle {
    font-size: 4rem;
    
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    position: relative;
    text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff,
      0.025em 0.04em 0 #fffc00;
    animation: glitch 725ms infinite;

    span {
        position: absolute;
        top: 0;
        left: 0;

        &:first-child {
            animation: glitch 500ms infinite;
            clip-path: polygon(0 0, 100% 0, 100% 35%, 0 35%);
            transform: translate(-0.04em, -0.03em);
            opacity: 0.75;
        }

        &:last-child {
            animation: glitch 375ms infinite;
            clip-path: polygon(0 65%, 100% 65%, 100% 100%, 0 100%);
            transform: translate(0.04em, 0.03em);
            opacity: 0.75;
        }

    }

    @keyframes glitch {
        0% {
          text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff,
            0.025em 0.04em 0 #fffc00;
        }
        15% {
          text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff,
            0.025em 0.04em 0 #fffc00;
        }
        16% {
          text-shadow: -0.05em -0.025em 0 #00fffc, 0.025em 0.035em 0 #fc00ff,
            -0.05em -0.05em 0 #fffc00;
        }
        49% {
          text-shadow: -0.05em -0.025em 0 #00fffc, 0.025em 0.035em 0 #fc00ff,
            -0.05em -0.05em 0 #fffc00;
        }
        50% {
          text-shadow: 0.05em 0.035em 0 #00fffc, 0.03em 0 0 #fc00ff,
            0 -0.04em 0 #fffc00;
        }
        99% {
          text-shadow: 0.05em 0.035em 0 #00fffc, 0.03em 0 0 #fc00ff,
            0 -0.04em 0 #fffc00;
        }
        100% {
          text-shadow: -0.05em 0 0 #00fffc, -0.025em -0.04em 0 #fc00ff,
            -0.04em -0.025em 0 #fffc00;
        }
      }
      

}

.tabArea {
    background: green;
    color: white;
    
    p {
      font-size: 1.25rem;
    }

    button {
      color: black !important;
    }
}

.thc {
    font-size: 2.5rem;
    font-weight: 500;
    color: white;
    padding-top: .25rem;
    // padding-bottom: .25rem;

}

.cardHeader{ 
    .IHS-span{
            // position: absolute;
            // bottom: 0px;
            // right: 0px;
            // background-color: white;
            // color: green;
            background-color: purple;
            color: white;
            padding: 3px 5px;
            z-index: 100;
            

            font-family: 'Ropa Sans', sans-serif;
            font-weight: 400;
            text-transform: uppercase;
            font-size: 1.5rem !important;
            line-height: 1.5rem !important;
        
    }
}


.cardDetails {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  background: green;
      box-shadow: inset 0 0 5px green;

  
}

.cardDetails-breeder {
    font-size: 1rem;
    text-transform: uppercase;
    color: green;
    font-weight: 500;
    // padding: auto 50%;
    background-color: white;
    text-align: center;
    display: inline-block;
    padding: 0 10px;
    box-shadow: inset 0 0 3px green;

}

.cardDetails-cross {
  font-size: 1.75rem;
  text-transform: uppercase;
  color: white;
  font-weight: 600;
  // padding: auto 50%;
  background-color: green;
  text-align: center;
  // display: inline-block;
  padding: 0 10px;

  box-shadow: inset 0 0 3px black;

}

.cardDetails-yield {
  // font-size: 1.5rem;
  // color: green;
}



.tth {
  padding-left: 2rem;

}

.cardDetails-harvesttime {
  font-size: 1.20rem;
  font-weight: 500;
  text-transform: uppercase;
  color: white;
  // display: flex;
  // align-items: center;
  // border: 2px solid green;
  // max-width: 75%;
  // box-shadow: inset 0 0 3px green;
  padding-right: 10px;
  

}

.tth-icon {
  // width: 5rem;
  // height: auto;
  height: 100%;
  vertical-align: middle;
  background: green;
  margin-right: 1rem;
  padding: 2px;
}

.yield-icon {
  // width: 100%;
  height: 80px;
  padding-left: 1rem;
  padding-top: 2px;
  padding-bottom: 2px;

  vertical-align: center;
  // background: green;

}